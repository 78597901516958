<template>
    <div class="index-page">
        <div class="index-first">
            <div class="index-header">
                <div class="logo-cell">
                    <img src="../../assets/pictures/logo.png" >
                    <div class="desc">{{$t('Hello')}}，{{user.name}}</div>
                </div>
                <div class="top-link flex-center" >
                    <!-- <router-link class="btn btn-circle" to="/signin" :class="filterSign && filterSign.length > 0 && filterSign[0].status == 1?'unactive':'active'">
                        <span class="dot" v-if="filterSign && filterSign.length > 0 && !filterSign[0].status == 1"></span>
                        <span class="iconfont icon-qiandao "></span>
                    </router-link> -->
                    <div class="btn btn-circle" @click="url('/message-list')">
                        <van-icon :name="require('../../assets/pictures/bell.png')" :badge="m_count == 0 ? '': (m_count > 99 ? '99+' : m_count)" />
                    </div>
                    <router-link class="btn btn-circle" to="/lang" v-if="select_lang > 0">
                        <span class="iconfont icon-yuyan"></span>
                    </router-link>
                </div>
            </div>
            <div class="index-sw-card">
                <div class="index-data" v-if="user2 && user2.username">
                    <div class="txt">{{$t('个人收益累计')}}</div>
                    <div class="lg">{{parseFloat(user2.stat.today_incomes).toFixed(6)}}<span>{{$t('USDT')}}</span></div>
                </div>
                <swiper :options="swiperMY" ref="my-swipe">
                    <template v-for="(item,index) in this.e_data">
                        <swiper-slide v-if="item.show_home" :key="index">
                            <div class="sw-data"  @click="url('/trend')">
                                <div class="col">
                                <div class="text"><img :src="item.logo" style="width: 10px"> {{item.name}}</div>
                                <div class="sw-val">{{item.close}}</div>
                                <div class="sw-per " :class="item.ratio > 0?'text-green':'text-orange'">
                                    <template v-if="item.ratio > 0">
                                        +
                                    </template>
                                    {{item.ratio}}%
                                    <i class="iconfont " :class="item.ratio > 0?'icon-up':'icon-dn'"></i>
                                </div>
                                </div>
                            </div>
                        </swiper-slide>
                    </template>
                </swiper>
            </div>

            <div class="sw-pdiv">
                <div class="sw-pagination" slot="pagination"></div>
            </div>
        </div>
        
        <div class="index-main">
            <div class="index-bg" >
                <particles
                color="#52cbeb"
                :particleOpacity="0.7"
                :particlesNumber="100"
                shapeType="circle"
                :particleSize="4"
                linesColor="#52cbeb"
                :linesWidth="1"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="2"
                clickMode="push"
                >
                </particles>
            </div>
            <div class="index-ad" @click="enter">
                <!-- <template v-if="banner1" >
                    <img :src="banner1.imgs" alt="">
                </template> -->
                <img :src="banner1.imgs" alt="">
                <div class="ad-grid">
                    <div class="lg">{{$t('活动中心')}}</div>
                </div>
            </div>
            <div class="index-panel index-robot-panel no-banner">
                <div class="index-hd">
                    <span class="tt">{{$t('机器人')}}</span>
                </div>
                <div class="bal-bar" @click="url('/robot')" style="margin: 10px auto;">
                    <img class="ro-logo" src="../../assets/pictures/logo.png" >
                    <div class="flex_bd">{{$t('查看我的机器人仓库')}}</div>
                    <van-icon name="arrow" />
                </div>
                <div class="has-robot ">
                    <span class="t">{{$t('我的机器人数量')}}：</span><span class="tag" @click="url('/robot')">{{parseInt(my_robot[0] + my_robot[1] + my_robot[2] + my_robot[3])}} {{$t('台')}}</span>
                </div>
                <div v-if="type_val == 0" class="robot-cells">
                    <ul class="robot-list">
                        <template v-for="(item,index) in robot" >
                        <li v-if="item.level != 0 && index < showNum" :key="index" :class="setClass(index)">
                            <div class="ro-list-box active" >
                                <div class="ro-heading flex-center">
                                    <div class="ro-left">
                                        <span class="tt">{{$t(item.name)}}</span>
                                    </div>
                                    <van-button type="primary" @click="buyRobotDialog(item)">{{$t('签约购买')}}</van-button>
                                </div>
                                <div class="ro-body">
                                    <div class="quo-item"><img src="../../assets/pictures/creative-commons-nc-line.png"><span>{{$t('价格')}}：</span><span
                                            class="text-blue">{{item.price}} {{$t('USDT')}}</span></div>
                                    <div class="quo-item"><img src="../../assets/pictures/history-fill.png"><span>{{$t('有效期')}}：</span><span
                                            class="text-blue">{{item.days}} {{$t('天')}}</span></div>
                                    <div class="quo-item"><img src="../../assets/pictures/jifen.png"><span>{{$t('做单价格')}}：</span><span
                                            class="text-blue">{{parseFloat(item.min_money).toFixed(2)}}
                                            {{$t('元/单')}}</span></div>
                                </div>
                            </div>
                        </li>
                        </template>
                    </ul>
                    <div class="loading-div text-gray" @click="loadingMore()" :class="showNum>1?'active':'no'">
                        <template v-if="!isLoading">
                            <span v-if="showNum>1">
                                {{$t('点击收起')}}
                                <van-icon name="arrow-up" />
                            </span>
                            <span v-else>
                                {{$t('点击显示更多')}}
                                <van-icon name="arrow-down" />
                            </span>
                            
                        </template>
                        <van-loading v-if="isLoading" size="20px">{{$t('加载中')}}...</van-loading>
                    </div>
                </div>
                <div v-if="type_val == 1" class="robot-cells">
                    <ul class="robot-list">
                        <template v-for="(item,index) in robot2" >
                        <li :key="index">
                            <div class="ro-list-box active">
                                <div class="ro-heading flex-center">
                                    <span class="tt">{{$t(item.t_name[0])}}</span>
                                    <van-button v-if="user.allow == 1" @click="buyRobotDialog2(item)">{{$t('签约')}}</van-button>
                                    <van-button v-else @click="openService">{{$t('联系客服开通权限')}}</van-button>
                                </div>
                                <div class="ro-body">
                                    <div class="quo-item">
                                        <img src="../../assets/pictures/creative-commons-nc-line.png">
                                        <span>{{$t('运营资金')}}：</span>
                                        <span class="text-blue">{{item.t_min_money.join(', ')}}</span>
                                        <span style="font-size: 12px"> {{$t('USDT')}}</span>
                                    </div>
                                    <div class="quo-item">
                                        <img src="../../assets/pictures/history-fill.png"><span>{{$t('有效期')}}：</span>
                                        <span class="text-blue">{{item.t_days.join(', ')}}</span>
                                        <span style="font-size: 12px"> {{$t('天')}}</span>
                                    </div>
                                    <div class="quo-item">
                                        <img src="../../assets/pictures/jifen.png"><span>{{$t('预计日收益')}}：</span>
                                        <span class="text-blue">{{item.t_profit[0]}} ~ {{item.t_profit[item.t_profit.length -1]}}</span>
                                    </div>
                                    <div style="color: #999999;font-size: 12px">
                                        <span>{{$t(item.t_desc[0])}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div class="index-main">
            <div class="index-bg" style="margin-top:-100px; height:400px;">
                <particles
                color="#52cbeb"
                :particleOpacity="0.7"
                :particlesNumber="100"
                shapeType="circle"
                :particleSize="4"
                linesColor="#52cbeb"
                :linesWidth="1"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="2"
                clickMode="push"
                >
                </particles>
            </div>
            <div class="index-panel">
                <div class="index-hd">
                    <span class="tt">{{$t('服务Service')}}</span>
                </div>
                <div class="serv-panel">
                    <van-row :gutter="10">

                        <van-col :span="12">
                            <router-link to="/deposit">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/chongzhi_active.png" >
                                    <div class="text">{{$t('充值')}}</div>
                                </div>
                            </router-link>
                        </van-col>

                        <van-col :span="12">
                            <router-link to="/withdraw">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/mianfeitixian.png" >
                                    <div class="text">{{$t('提现')}}</div>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col :span="12">
                            <router-link :to="'/share?key=' + key + '&uname=' + user.name">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/fenxiang.png" >
                                    <div class="text">{{$t('分享邀请')}}</div>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col :span="12">
<!--                            <router-link to="/customer-serv">-->
                            <a @click="openService">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/kefu-mianxing.png" >
                                    <div class="text">{{$t('网上客服')}}</div>
                                </div>
                            </a>
<!--                            </router-link>-->
                        </van-col>
                        <van-col :span="12">
                            <router-link to="/help">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/bangzhuwendang.png" >
                                    <div class="text">
                                        {{$t('帮助指南')}}
                                    </div>
                                </div>
                            </router-link>
                        </van-col>
                        <van-col :span="12">
                            <router-link to="/about">
                                <div class="serv-link">
                                    <img src="../../assets/pictures/guanyuwomen.png" >
                                    <div class="text">
                                        {{$t('关于我们')}}
                                    </div>
                                </div>
                            </router-link>
                        </van-col>
                    </van-row>
                </div>
            </div>
        </div>
        <div class="index-main">
            <div class="index-bg" style="margin-top:-50px; height:200px;">
                <particles
                color="#52cbeb"
                :particleOpacity="0.7"
                :particlesNumber="100"
                shapeType="circle"
                :particleSize="4"
                linesColor="#52cbeb"
                :linesWidth="1"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="2"
                clickMode="push"
                >
                </particles>
            </div>
            <div class="index-panel">
                <div class="index-hd" >
                    <span class="tt">{{$t('合作伙伴')}}</span>
                </div>
                <div class="pSwiper">
                    <swiper :options="swiperParter" ref="pSwiper" v-if="exchange.length"
                    >
                        <swiper-slide v-for="(item, index) in exchange" :key="index">
                            <div class="par-box">
                                <img :src="item.logo">
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
        <Foot :active="0"/>
        <van-popup class="bs-popup" v-model="notice_alert.visible" position="center" closeable
                   style="background-color:unset"
                   :style="{ borderRadius: '10px', width: notice_alert.type == '1' ? '80%': 'unset'}">
            
            <div class="content" v-html="notice_alert.text" v-if="notice_alert.type == 1"></div>
            <div class="wt-dialog notice-dialog" v-else >
                <div class="wt-top"><div v-if="notice_alert.type != '1'" class="del3" style="width: 26px; margin-right: 5px;"><img src="../../assets/pictures/robot_v.svg" alt=""></div>{{$t('Notice')}}</div>
                <div class="notic-body">
                    <div class="cont" v-html="notice_alert.text">
                    </div>
                </div>
            </div>
        </van-popup>
        <van-popup class="bs-popup " v-model="buyRobotShow" closed="pclosed" position="bottom" closeable>
            <div class="bs-popup-dialog">
                <div class="bs-popup-head">
                    <div class="tt">{{$t('签约购买')}}</div>
                </div>
                <div class="dialog-v-body">
                    <div class="dv-card">
                        <div class="dv-row">
                            <div class="dv-data">
                                <div class="label">{{$t('每月')}}</div>
                                <div class="val">{{buyRobotShowInfo.price}} <span style="font-size: 12px">{{$t('USDT')}}</span></div>
                            </div>
                            <div class="dv-data" style="text-align: right">
                                <div class="label">{{$t('预计每日收益')}}</div>
                                <div class="val">
                                    <span>{{parseFloat(buyRobotShowInfo.profit).toFixed(2)}}</span>
                                    <span style="font-size: 12px">{{$t('USDT')}}</span></div>
                            </div>
                        </div>
                        <div class="dv-row">
                            <div class="dv-data">
                                <div class="label">{{$t('有效期')}}</div>
                                <div class="val">{{buyRobotShowInfo.days}} {{$t('天')}}</div>
                            </div>
                            <div class="dv-data" style="text-align: right">
                                <div class="label">{{$t('做单价格')}}</div>
                                <div class="val">
                                    {{parseFloat(buyRobotShowInfo.min_money).toFixed(2)}}
                                    {{$t('元/单')}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dv-card" >
                        <div class="dv-flex ">
                            <div class="flex_bd">
                                <div class="dv-less">
                                    <div class="label text-gray">{{$t('当前余额')}}</div>
                                    <div class="value">{{parseFloat(user.balance).toFixed(6)}} <span style="font-size: 12px">{{$t('USDT')}}</span></div>
                                </div>
                            </div>
                            <div class="h-stp">
                                <van-stepper :disabled="numDisabled" v-model="num" disable-input  center  clearable @plus="plus" @minus="minus"/>
                            </div>
                        </div>
                        <div class="dv-foot">
                            <van-button type="primary"  @click="buyRobot">{{$t('支付')}} {{parseFloat(pay_money).toFixed(2)}} {{$t('USDT')}}</van-button>
                        </div>
                    </div>
                    <div class="nt-tip">
                        <div class="hd text-green">{{$t('温馨提示')}}：</div>
                        <div class="bd">
                            <p>{{$t('机器人可购买，买卖，转让。')}}</p>
                            <p>{{$t('每个等级机器人，同时可运行一个，请保障帐户操作本金充足。')}}</p>
                            <p>{{$t('机器人具有时效性，请充分运行机器人，赚取更多收益。')}}</p>
                            <p>{{$t('机器人级别越高，做单时间更有效率，更能捕捉收益。')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup class="bs-popup " v-model="buyRobotShow2" position="bottom" closeable>
            <div class="bs-popup-dialog">
                <div class="bs-popup-head">
                    <div class="tt">{{$t('签约')}}</div>
                </div>
                <div class="dialog-v-body">
                    <div class="dv-card">
                        <div class="dv-row2">
                            <div class="dv-t1">
                                {{$t('投入金额')}}
                            </div>
                            <div class="dv-t-main">
                            <template v-for="(item, index) in item1">
                                <div class="dv-t" v-bind:key="index">
                                    <div class="dv-item" :class="item_active[0] == item ? 'dv-active' : ''" @click="change2(0, parseFloat(item))">{{parseFloat(item)}}</div>
                                </div>
                            </template>
                            </div>
                        </div>
                        <div class="dv-row2">
                            <div class="dv-t1">
                                {{$t('周期')}}
                            </div>
                            <template v-for="(item, index) in item2">
                                <div class="dv-t" v-bind:key="index">
                                    <div class="dv-item" :class="item_active[1] == item ? 'dv-active' : ''" @click="change2(1, item)">{{item}}</div>
                                </div>
                            </template>
                        </div>
                        <div class="dv-row2">
                            <div class="dv-t1">
                                {{$t('模式')}}
                            </div>
                            <template v-for="(item, index) in item3">
                                <div class="dv-t" v-bind:key="index">
                                    <div class="dv-item" :class="item_active[2] == item ? 'dv-active' : ''" @click="change2(2, item)">{{item ? $t('不可赎回'):$t('可赎回')}}</div>
                                </div>
                            </template>
                        </div>
                        <div class="dv-row2">
                            <div class="dv-t1">
                                {{$t('预估日收益')}}
                            </div>
                            <div class="dv-t" >
                                <div class="dv-item dv-active">{{parseFloat(item4.profit ? item4.profit: 0) }} %</div>
                            </div>
                        </div>
                    </div>
                    <div class="dv-card">
                        <div class="dv-less">
                            <div class="label text-gray">{{$t('当前余额')}}</div>
                            <div class="value">{{parseFloat(user.balance).toFixed(6)}} <span style="font-size: 12px">{{$t('USDT')}}</span></div>
                        </div>
                        <div class="dv-foot">
                            <van-button type="primary"  @click="buyRobot2(item4.id)" :disabled="item4.id > 0 ? false: true">{{$t('支付')}} {{item4.min_money ? parseFloat(item4.min_money) : '?' }} {{$t('USDT')}}</van-button>
                        </div>
                    </div>
                    <div class="nt-tip">
                        <div class="hd">{{$t('说明')}}：</div>
                        <div class="bd">
                            <p v-if="buyRobotShowInfo2.t_desc">{{$t(buyRobotShowInfo2.t_desc[0])}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup class="index-popup" v-model="show" position="center" closeable >
            <div class="popup-ad">
                <div class="ad-grid">
                    <div class="lg"><span>0</span>{{$t('投入')}}</div>
                    <div class="md">{{$t('首次投资')}}</div>
                </div>
                <div class="ftt">
                    <van-button type="warning" @click="firstInvestmentShowf2">{{$t('开启首次投资')}}</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";
    import { Dialog } from 'vant';
    import particles from "@/components/vue-particles/vue-particles.vue";
    import { Toast } from 'vant';
    export default {
        components: {
            Foot,
            particles
        },
        data() {
            return {
                select_lang:false,
                typeOp:[
                    {txt: this.$t('普通机器人'), val: 0},
                    // {txt: this.$t('保全理财机器人'), val: 1},
                ],
                type_val: 0,
                show: false,
                show2: true,
                name_logo: '',
                loading: true,
                num: 1,
                numDisabled: false,
                pay_money: '',
                user: {name: '', money: 0.00, balance: 0.00},
                firstInvestmentShow: false,
                firstInvestmentInfo: [],
                buyRobotShow: false,
                buyRobotShow2: false,
                buyRobotShowInfo: [],
                buyRobotShowInfo2: [],
                notices: '',
                notice_alert: {
                    text: "",
                    visible: false,
                    id: "",
                    type: '',
                },
                e_data: [],
                robot: [],
                robot2: [],
                my_robot: [0, 0, 0, 0],
                banner0: '',
                banner1: '',
                banner2: '',
                b_timer: null,
                b_load: false,
                m_count: '',
                swipe_index: 0,
                swipe_c: 0,
                swiperParter: {
                    loop:true,
                    autoplay: {
                        delay: 2000,
                        disableOnInteraction: false,
                    },
                    observer: true,
                    observeParents: true,
                    slidesPerGroup: 3,
                    slidesPerView: '3',
                    spaceBetween: 10,
                },
                swiperMY: {
                    observer: true,
                    observeParents: true,
                    slidesPerView: '3',
                    // centeredSlides: true,
                    spaceBetween: 10,
                    // loop:true,
                    // autoplay: {
                    //     delay: 4500,
                    //     disableOnInteraction: false,
                    // },
                    pagination: {
                        el: '.sw-pagination'
                    },
                },
                exchange: [],
                agent_list: [],
                item_active: [-1, -1, -1, -1],
                item1:[],
                item2:[],
                item3:[],
                item4:[],
                isFirst:true,
                robotpassword:'',
                isLoading:false,
                showNum:1,
                current_date:'',
                signlist:[],
                isSign:false,
                user2: {},
                key:'',
                bank_list:[],
                receive_robot_bank:0,
            }
        },
        methods: {
            getKey() {
                this.$axios
                    .get('/user/codes')
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.key = res.data.data;
                            
                        }
                    });
            },
            loadingMore(){
                if (this.showNum == 1) {
                    this.isLoading = true;
                    setTimeout(() => {
                        this.isLoading = false;
                        this.showNum = 999;
                    }, 600); // 
                } else {
                    this.showNum = 1;
                }
                
            },
            getRobotConfig(level, type){
                let that = this;
                this.$axios
                    .post(this.$api_url.get_robot_config, this.$qs.stringify({l: level, type: type, c: this.item_active}))
                    .then((res) => {
                        if(res.data.code == 0){
                            if(type == 1){
                                that.item1 = res.data.data;
                            }
                            if(type == 2){
                                that.item2 = res.data.data;
                            }
                            if(type == 3){
                                that.item3 = res.data.data;
                            }
                            if(type == 4){
                                that.item4 = res.data.data;
                            }
                        }
                    });
            },
            change2(i, index){
                this.item_active.splice(i, 1, index);
                for(let ii = i + 1; ii < this.item_active.length; ii++){
                    this.item_active.splice(ii, 1, -1);
                }

                if(i == 0){
                    this.item4 = [];
                    this.item3 = [];
                    this.item2 = [];
                }
                if(i == 1){
                    this.item4 = [];
                    this.item3 = [];
                }
                if(i == 2){
                    this.item4 = [];
                }
                this.getRobotConfig(this.buyRobotShowInfo2.info[0].level, i + 2);
            },
            changeType(val){
                this.type_val = val;
            },
            openService(){
                window._vue.openService();
            },
            toggleA(index) {
                var check = this.robot[index].check;
                this.robot[index].check = check === true ? false : true;
                this.$forceUpdate()
            },
            setClass(value) {
                let obj = {face: true}
                obj["ro-style-" + `${value % 4}`] = true
                return obj
            },
            onChange(index) {
                this.swipe_index = index;
            },
            changeNum(isFirst) {
                if(isFirst){
                    this.pay_money = this.buyRobotShowInfo.price * this.num
                }else{
                    this.pay_money = this.buyRobotShowInfo.price * this.num
                }
            },
            plus(){
                this.num++
                this.changeNum(false);
            },
            minus(){
                this.num--
                this.changeNum(false);
            },
            getBanks() {
                this.$axios.get(this.$api_url.get_bind_bank + '?id=-1&tag=list').then((responese) => {
                    if(responese.data.code == 0 ){
                        this.bank_list = responese.data.data.info;
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            firstInvestmentShowf() {
                this.isFirst = true
                if (this.receive_robot_bank == '1' && this.isFirst && this.bank_list.length < 1 ){
                    Dialog.confirm({
                        message: this.$t('请先绑定银行卡'),
                        confirmButtonText: this.$t('确定'),
                        cancelButtonText: this.$t('取消'),
                        confirmButtonColor: 'linear-gradient(to right,#52cbeb, #3d8cc6)',
                        className: 'darkDialog',
                        theme: 'round-button',
                    })
                    .then(() => {
                    // on confirm
                        this.url('/bank');
                    })
                    .catch(() => {
                    // on cancel
                    });
                    return;
                }
                this.buyRobotShowInfo = this.robot.filter(item => item.level == 0).pop();
                this.buyRobotShow = true;
                this.num = 1;
                this.numDisabled = true;
                this.changeNum(this.isFirst);
            },
            firstInvestmentShowf2(){
                this.show = false;
                this.firstInvestmentShowf();
            },
            url(url) {
                this.$router.push({
                    path: url
                })
            },
            getData() {
                this.$axios
                    .get(this.$api_url.get_home_data)
                    .then((res) => {
                            if (res.data && res.data.code == 0) {
                                this.loading = false;
                                this.notices = res.data.data.notices;
                                this.robot = res.data.data.robot;
                                this.robot2 = res.data.data.robot2;
                                this.robot[0].check = true;
                                // this.partner = res.data.data.b_partner;
                                this.exchange = res.data.data.b_exchange;
                                this.e_data = res.data.data.e_data;
                                this.banner0 = res.data.data.banner0;
                                this.swipe_c = 1 + (this.banner0 ? this.banner0.length : 0);
                                this.banner1 = res.data.data.banner1;
                                this.banner2 = res.data.data.banner2;
                                this.my_robot = res.data.data.my_robot;
                                // this.show = res.data.data.free_robot > 0 ? false : true;
                                this.m_count = res.data.data.m_count;
                                this.user = res.data.data.user;
                                // this.$refs.mySwiper.swiper.slideTo(this.user.level);
                                this.agent_list = res.data.data.agent_list;

                                if (res.data.data.notice_alert != undefined) {
                                    this.notice_alert.text = res.data.data.notice_alert.title;
                                    this.notice_alert.id = res.data.data.notice_alert.id;
                                    this.notice_alert.type = res.data.data.notice_alert.type;
                                    let old_id = sessionStorage.getItem("notice_id");
                                    if (old_id != this.notice_alert.id) {
                                        
                                        sessionStorage.setItem("notice_id", this.notice_alert.id);
                                    }
                                    this.notice_alert.visible = true;
                                }
                            }
                        }
                    )
                    .catch((err) => {
                        console.log();
                        // this.$toast.fail(err.message);
                    });
            },
            getB() {
                if (this.b_load) {
                    return;
                }
                this.b_load = true;
                this.$axios.get(this.$api_url.get_b).then((res) => {
                    this.e_data = res.data;
                    this.b_load = false;
                });
            },
            buyRobot() {
                let param = {
                    id: this.buyRobotShowInfo.id,
                    num: this.num
                };
                this.$axios
                    .post('/robot/buy_robot', param)
                    .then((res) => {
                        let result = res.data;
                        if (result.code === 0) {
                            this.buyRobotShow = false;
                            this.$toast.success(result.msg);
                            // this.getData();
                            this.url('/robot');
                        } else {
                            this.$toast.fail(result.msg);
                        }
                    });
            },
            buyRobot2(id) {
                this.$axios
                    .post('/robot/buy_robot2', {id: id})
                    .then((res) => {
                        let result = res.data;
                        if (result.code === 0) {
                            this.buyRobotShow2 = false;
                            this.$toast.success(result.msg);
                            // this.getData();
                            this.url('/robot');
                        } else {
                            this.$toast.fail(result.msg);
                        }
                    });
            },
            buyRobotDialog(item) {
                this.isFirst = false
                this.buyRobotShow = true;
                this.buyRobotShowInfo = item;
                this.num = 1;
                this.changeNum(this.isFirst);
                this.numDisabled = false;
            },
            buyRobotDialog2(item) {
                console.log(item);
                this.buyRobotShow2 = true;
                this.buyRobotShowInfo2 = item;
                this.getRobotConfig(item.info[0].level, 1);
            },
            async checkLogin() {
                await this.$axios.get(this.$api_url.v_login)
                    .catch((err) => {
                        console.log(err.message);
                        // this.$toast.fail(err.message);
                    });
            },
            enter(){
                this.$toast(this.$t('敬请期待'));
            },
            getSign() {
                this.$axios
                    .get(this.$api_url.get_signin_info)
                    .then((res) => {
                        if(res.data.code == 0){
                            this.signlist = res.data.data.info;
                            this.current_date = res.data.data.current_date;
                        }
                    });
            },
            getUserData() {
                // setTimeout(()=>{
                this.$axios
                    .get(this.$api_url.user + "?type=2")
                    .then((res) => {
                        this.user2 = res.data;
                    });
                // }, 20002);
            },
        },
        computed: {
            filterSign() {
                return this.signlist.filter(item => item.date === this.current_date);
            }
        },
        mounted() {
            this.getData();
            this.getUserData()
            this.b_timer = setInterval(() => {
                this.getB()
            }, 1000);
            this.getSign();
            this.select_lang = localStorage.getItem('app.select_lang') || false;
            this.receive_robot_bank = localStorage.getItem('app.receive_robot_bank') || '0';
            this.getKey()
            this.getBanks()
        },
        destroyed() {
            clearInterval(this.b_timer);
        },
        created() {
            this.name_logo = localStorage.getItem('app.title') || this.$t('Vault');
            this.checkLogin();
        },
    }
</script>
<style>
    .christ-a{
        animation: spin2 2s linear infinite;
    }

    .type_main{
        display: flex;
        font-size: 14px;
        color: #0765f4;
    }

    .type_div{
        color: #0765f4;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .type_active{
        color: #ffecec;
        background-color: #0184fa;
    }

    @keyframes spin2 {
        0% {
            opacity: 0.6;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.6;
        }
    }
    .face{
        position: relative;
    }
    .roMore{
        position: relative;
        z-index: 2;
    }
    .home-quo-item-bg-wapper{
        position: absolute;
        left: 0;
        top: 50px;
        bottom: 0;
        width: 100%;
        background: linear-gradient(to right, #01030d ,#001c85);
        border-radius: 0 0 10px 10px;
        overflow: hidden;
    }
    .jiqiren-bg{
        width: 100%;
        max-width: 100%;
    }
    .home-quo-item-img-wapper{
        display: flex;
    }
    .ps-input-row{
        margin-bottom: 15px;
    }
    .ps-input-row .van-cell{
        border-radius: 30px;
        background-color: #f2f3f5;
    }
    .icon-qiandao{
        color: #ffffff;
    }
    .active .icon-qiandao{
        display: inline-block;
        color: #ffb11b;
        animation: tada 2s ease-in-out 16;
    }
    .btn-circle .dot{
        width: 10px;
        height: 10px;
        border-radius: 20px;
        background-color: #ff0000;
        display: inline-flex;
        position: absolute;
        right: 0;
        top: 0;
    }



    @-webkit-keyframes tada {
        0% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1)
        }
    
        10%,20% {
            -webkit-transform: scale3d(.9,.9,.9) rotate(-3deg);
            transform: scale3d(.9,.9,.9) rotate(-3deg)
        }
    
        30%,50%,70%,90% {
            -webkit-transform: scale3d(1.1,1.1,1.1) rotate(3deg);
            transform: scale3d(1.1,1.1,1.1) rotate(3deg)
        }
    
        40%,60%,80% {
            -webkit-transform: scale3d(1.1,1.1,1.1) rotate(-3deg);
            transform: scale3d(1.1,1.1,1.1) rotate(-3deg)
        }
    
        to {
            -webkit-transform: scaleX(1);
            transform: scaleX(1)
        }
    }
    
    @keyframes tada {
        0% {
            -webkit-transform: scaleX(1);
            transform: scaleX(1)
        }
    
        10%,20% {
            -webkit-transform: scale3d(.9,.9,.9) rotate(-3deg);
            transform: scale3d(.9,.9,.9) rotate(-3deg)
        }
    
        30%,50%,70%,90% {
            -webkit-transform: scale3d(1.1,1.1,1.1) rotate(3deg);
            transform: scale3d(1.1,1.1,1.1) rotate(3deg)
        }
    
        40%,60%,80% {
            -webkit-transform: scale3d(1.1,1.1,1.1) rotate(-3deg);
            transform: scale3d(1.1,1.1,1.1) rotate(-3deg)
        }
    
        to {
            -webkit-transform: scaleX(1);
            transform: scaleX(1)
        }
    }
    



</style>